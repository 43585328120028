import React from "react";
import styled from "styled-components";
import Modal from "ui/Modal";
import { ReactComponent as CloseIcon } from "assets/images/cross_icon.svg";

type CustomModalProps = {
  children: React.ReactNode;
  open: boolean;
  updateOpen: () => void;
  styles?: any;
  closeIcon?: boolean;
  title?: string;
  isFullscreen?: boolean;
};

const CustomModal = ({
  children,
  open,
  updateOpen,
  styles,
  closeIcon = true,
  title,
  isFullscreen = true,
}: CustomModalProps): JSX.Element => {
  const handleClose = () => {
    updateOpen();
  };

  return (
    <>
      <Modal open={open} isFullScreen={isFullscreen} onClose={handleClose}>
        <Background isFullscreen={isFullscreen} style={styles}>
          <Row>
            {title && <Title>{title}</Title>}
            {closeIcon && (
              <IconContainer>
                <CloseIcon onClick={handleClose} />
              </IconContainer>
            )}
          </Row>
          {children}
        </Background>
      </Modal>
    </>
  );
};

export default CustomModal;

const Background = styled.div<{ isFullscreen?: boolean }>`
  position: relative;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.background.main};
  height: 100%;
  width: 100%;
  border-radius: ${({ isFullscreen }) => (isFullscreen ? "0px" : "10px")};
`;

const Title = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary.heading};
  box-sizing: border-box;
  padding: 30px 0;
  font-size: 26px;
  line-height: 30px;
  align-items: center;
  text-align: center;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    & > svg > path {
      stroke: ${({ theme }) => theme.primary.heading};
    }
  }
`;
