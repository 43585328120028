import { useEffect } from "react";
import { useGetStaticPageMutation } from "../redux/api/appApi";
import styled from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ExitIcon } from "../assets/images/cross_icon.svg";
import IconButton from "../ui/material-kit/IconButton";

interface StaticPageProps {
  isMobile?: boolean;
  id?: number;
}

const StaticPage = (props: StaticPageProps) => {
  const { isMobile, id } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [getStaticPage, { isLoading }] = useGetStaticPageMutation();

  const { page } = useTypedSelector((state) => {
    return {
      page: state.appSlice.pages.find(
        (item) => `/static/${item.url}` === location.pathname
      ),
    };
  });
  useEffect(() => {
    if (!page)
      getStaticPage(1).then((pld) => {
        if ("data" in pld) {
          window.scrollTo(0, 0);
        }
        if ("error" in pld) {
          navigate("/");
        }
      });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  if (isMobile) {
    return (
      <MobileWrapper>
        <Title style={{ gridTemplateColumns: "32px 10fr 32px" }}>
          <div />
          {page?.title}
          <Icon
            id={`static-page-exit`}
            onClick={() => navigate("/")}
            style={{ alignSelf: "start" }}
          >
            <ExitIcon />
          </Icon>
        </Title>
        <ContentContainer dangerouslySetInnerHTML={{ __html: page!.content }} />
      </MobileWrapper>
    );
  } else {
    return (
      <DesktopWrapper>
        <Title>{page?.title}</Title>
        <ContentContainer dangerouslySetInnerHTML={{ __html: page!.content }} />
      </DesktopWrapper>
    );
  }
};

export default StaticPage;

const DesktopWrapper = styled.div`
  padding: 0 32px 15px;
  flex-direction: column;
  box-shadow: none;
  min-height: 576px;
`;

const MobileWrapper = styled.div`
  padding: 0 10px 15px;
  flex-direction: column;
  box-shadow: none;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  max-width: inherit;
  overflow: hidden;
  text-align: center;
  padding: 25px 0;
  font-size: 24px;
  text-transform: uppercase;
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-transform: unset;

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 6px;
    background-color: ${(props) => props.theme.primary.light};
  }

  & > * {
    line-height: 1em;
  }

  & > p {
    margin: 0.5em;
    padding: 0;
  }
`;

const Icon = styled(IconButton)``;
