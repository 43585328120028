import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { appDispatch, AppDispatch } from "redux/store";
import { userAPI } from "redux/api/userApi";
import appMiddleware from "./appMiddleware";
import usersMiddleware from "./userMiddleware";
import { toast } from "react-toastify";
import { t } from "i18next"
import userMiddleware from "./userMiddleware";
import HallOfFameMiddleware from "./hallOfFameMiddleware";

const extraMiddlewares: { [key: string]: (api: MiddlewareAPI, dispatch: Dispatch<AnyAction>, action: any) => void; } = {
    appApi: appMiddleware,
    userApi: userMiddleware,
    usersApi: usersMiddleware,
    hallOfFameApi: HallOfFameMiddleware
};

interface IParam {
    name: string,
    conclusion?: boolean
}

const RtkCommonMiddleware: Middleware = (api: MiddlewareAPI) => (dispatch) => (action) => {
    const { payload, type, meta, error } = action;
    const currentType = type.split("/")[0];
    const requestName = type.split("/")[1];
    const status = type.split("/")[2];
    const method = meta?.baseQueryMeta?.request?.method || payload?.method;
    const showToast = meta?.arg?.originalArgs?.toast

    if (status !== "fulfilled" && status !== "rejected") {
        return dispatch(action);
    }

    if (method === 'GET') {
        return dispatch(action);
    }

    if (payload?.status === 401) {
        appDispatch(userAPI.endpoints.logoutMe.initiate());
        return dispatch(action);
    }

    if (status === 'rejected' && showToast !== 'success' && showToast !== 'none') {
        if (payload?.data?.code !== undefined)
            // @ts-ignore
            toast.error(t(`messages.error_code.${payload?.data?.code}`))
        return dispatch(action);
    }
    //console.log(showToast, payload, type, meta, error, requestName, 'test')
    if (status === "fulfilled" && (showToast === 'all' || showToast === 'success')) {
        // @ts-ignore
        toast.success(t('messages.success.operation_was_successful'))
        return dispatch(action);
    }
    //
    // if (method === "GET" && status === "rejected" && currentType !== 'transactionApi') {
    //     toast.error(t('common.errors.something_went_wrong'))
    //     return dispatch(action);
    // }

    const exMiddleware = extraMiddlewares[`${currentType}`];
    if (exMiddleware) {
        exMiddleware(api, dispatch, action);
    }

    return dispatch(action)
}


export default RtkCommonMiddleware;
