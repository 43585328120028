import React, {useEffect, useMemo, useState} from "react";
import {useGetCategoriesQuery} from "redux/api/gamesApi";
import {useTypedSelector} from "hooks/useTypedRedux";
import styled, {css} from "styled-components";
import {Mousewheel, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {useTranslation} from "react-i18next";
import useDimensions from "react-cool-dimensions";
import Button from "../../ui/material-kit/Button";
import useMediaQuery from "../../hooks/useMediaQuery";
import {device} from "../../constants/deviceSizes";
import {toast} from "react-toastify";
import {t} from "i18next";
import {useCustomToast} from "../../hooks/useCustomToast";
import {userSelector} from "../../redux/selectors/userSelector";
import {isMobileSelector} from "../../redux/selectors/appSelector";
import {categoriesSelector} from "../../redux/selectors/gamesSelector";

interface CategoriesProps {
    loading?: boolean,
    category?: string,
    onChange: (v: string) => void,
    height?: number,
    heightImg?: number,
    width?: number,
    setProvider?: (v: string) => void
}


const CategoriesContainer = (props: CategoriesProps): JSX.Element => {
    const {category, onChange} = props;
    const {i18n, t} = useTranslation()
    const {} = useGetCategoriesQuery();

    const user = useTypedSelector(userSelector)
    const categories = useTypedSelector(categoriesSelector)
    const isMobile = useTypedSelector(isMobileSelector)

    const mobile = useMediaQuery(device.mobileXL)
    const tabletS = useMediaQuery(device.tabletS)
    const tabletM = useMediaQuery(device.tabletM)
    const {observe, width} = useDimensions({});

    const columns = useMemo(() => {
        if (mobile) return 3
        if (tabletS) return 4
        if (tabletM) return 5
        return 6
    }, [width])
    const callToast = useCustomToast({text: t('messages.alert.please_login'), type: "error"})

    if (!categories.length) {
        return <></>
    } else {
        return (
            <>
                <SwiperContainer ref={observe}>
                    <Swiper
                        style={{zIndex: 0}}
                        slidesPerView={columns}
                        spaceBetween={10}
                        mousewheel={true}
                        initialSlide={categories.findIndex(item => item.content[0].title.toLowerCase() === category?.toLowerCase()) || 0}
                        modules={[Mousewheel, Navigation]}
                    >
                        {categories.map((item, index) => {
                            const categoryName = item.content.find(it => it.lang === i18n.language)?.title
                            if (!categoryName) return (
                                <></>
                            )
                            return (
                                <SwiperSlide
                                    key={`filter-${categoryName}-${index}`}>
                                    <FilterContainer color={'secondary'} isMobile={isMobile}
                                                     check={category?.toLocaleLowerCase() === categoryName.toLocaleLowerCase()}
                                                     onClick={() => {
                                                         if (item.content[0].title.toLowerCase() === 'favorite' && !user) {
                                                             callToast()
                                                             return
                                                         }
                                                         onChange(categoryName)
                                                     }}
                                     id={`filter-${categoryName}-${index}`}>
                                        <Text check={category === categoryName}>
                                            {categoryName}
                                        </Text>
                                        <Image isMobile={isMobile} src={item.img_url} alt={categoryName}/>
                                    </FilterContainer>

                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </SwiperContainer>
            </>
        )
    }
}

export default React.memo(CategoriesContainer);

const SwiperContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

`

const FilterContainer = styled(Button) <{ check?: boolean, isMobile?: boolean }>`
  font-size: ${({isMobile}) => isMobile
          ? device.mobileXL
                  ? '14px'
                  : '16px'
          : '24px'};
  max-width: ${({isMobile}) => isMobile ? '200px' : '300px'};
  min-width: 108px;
  height: 96px;
  flex-direction: ${({isMobile}) => isMobile ? 'column' : 'row'};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: ${({isMobile}) => isMobile ? 'space-around' : 'center'};
  text-transform: uppercase;

  & > img {
    padding-left: ${({isMobile}) => isMobile ? '0' : '10px'};
    width: 50px;
  }
`

const Text = styled.div<{ check?: boolean }>`
  color: ${({check, theme}) => check ? theme.primary.main : theme.text.white};
  flex-wrap: nowrap;
`

const Image = styled.img<{ isMobile?: boolean }>`
  ${({isMobile}) => isMobile && css`
    max-width: 42px;
    max-height: 42px;
  `};
`