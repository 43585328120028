import React, {useEffect, useMemo, useState} from 'react';
import styled, {css} from 'styled-components';
import {useGetGameListMutation} from "../../redux/api/gamesApi";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import {FixedSizeGrid} from "react-window";
import openGame from "../../helpers/openGame";
import GameCard from "../../ui/GameCard";
import useDimensions from "react-cool-dimensions";

interface IGamesPageContainer {
    value: {
        game?: string,
        provider?: string
        category?: string
    }
}

const GamesListContainerDesktop = (props: IGamesPageContainer) => {
    const {game, provider, category} = props.value;
    const [getGameList, {isLoading}] = useGetGameListMutation();
    const {data, user} = useTypedSelector((state) => {
        return {
            user: state.userSlice.user,
            data: state.gamesSlice.gameList.toArray().map(item => item[1]).slice(0, 22),
        }
    })


    useEffect(() => {
        getGameList({
            data: {
                type: 'update',
                title: game,
                size: 30,
                provider,
                category
            }
        })
    }, [game, provider, category])

    const {observe, width} = useDimensions({});

    return (
        <Wrapper ref={observe} length={data.length} style={{gridAutoRows: `${(width - 100) / 7}px`}}>
            {data.map((item, index) =>
                <React.Fragment key={`game-${index}-${item.Name}`}>
                    <GameCard game={item} isAuth={!!user} isLazy={true}/>
                </React.Fragment>
            )}
        </Wrapper>
    );
};

export default GamesListContainerDesktop;

const Wrapper = styled.div<{ length: number }>`
  display: grid;
  justify-items: center;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 16px;
  padding: 8px;
  max-width: 100%;

  > div {
    width: 100%;
    height: 100%;

    ${({length}) => length > 11 && css`
      :nth-child(6) {
        grid-column: 2 span;
        grid-row: 2 span;
      }
    `}
    ${({length}) => length > 12 && css`
      :nth-child(12) {
        grid-column: 2 span;
        grid-row: 2 span;
      }
    `}
  }
`