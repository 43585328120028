import DesktopWrapper from "components/profile/DesktopWrapper";
import { MessagesWrapper } from "components/profile/MessagesWrapper";

const Messages = (): JSX.Element => {
  return (
    <DesktopWrapper
      title={"PROFILE_MESSAGES"}
      childrenResult={null}
      childrenInfo={<MessagesWrapper />}
    />
  );
};

export default Messages;
