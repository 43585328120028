import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ArrowButton from "ui/ArrowButton";
import {useTranslation} from "react-i18next";

type ScrollButtonType = {
    isDesktop?: boolean
}

const ScrollButton = (props: ScrollButtonType) => {
    const [scrollState, setScrollState] = useState(false);
    const wrapper = useRef(document.querySelector('#main-wrapper'))
    const {i18n} = useTranslation();
    useEffect(() => {
        wrapper.current = document.querySelector('#main-wrapper')
        const timer = setInterval(() => {
            wrapper.current && setScrollState(wrapper.current.scrollTop > 0)
        }, 1000)
        return () => clearTimeout(timer);
    }, []);

    const handleScroll = useCallback(() => {
        wrapper.current?.scrollTo({top: 0, behavior: 'smooth'})
    }, [])

    return (
        <>
            {scrollState &&
                <FixedButtonContainer isRight={i18n.dir() === 'rtl'}>
                    <ArrowButton styles={props.isDesktop ? {
                        maxWidth: 50,
                        maxHeight: 50,
                        minWidth: 50,
                        minHeight: 50,
                    } : undefined} onClick={handleScroll}/>
                </FixedButtonContainer>}
        </>
    );
};

export default ScrollButton;


const FixedButtonContainer = styled.div<{isRight: boolean}>`
  position: fixed;
  ${({isRight}) => isRight ? 'left: 17px;' : 'right: 17px;'};
  bottom: 80px;
  z-index: 100;
`
