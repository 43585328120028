import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useCreateSessionMutation, useGetGameInfoMutation} from "../redux/api/gamesApi";
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import DesktopGame from "../components/games/DesktopGame";
import {IGame} from "../redux/types/gamesTypes";
import AppLoader from "../assets/loaders/AppLoader";

const Game = (): JSX.Element => {
    const [dataGame, setDataGame] = useState("");
    const [gameInfo, setGameInfo] = useState<IGame | undefined>(undefined);
    const {t, i18n} = useTranslation()
    const location = useLocation();
    const navigate = useNavigate();
    const {gameId, providerId} = useParams();
    const [createSession, {data: sessionData, isSuccess, isError, isLoading, error}] = useCreateSessionMutation();
    const [getGameInfo, {isLoading: isLoadingInfo, data: infoData}] = useGetGameInfoMutation();

    useEffect(() => {
        getGameInfo(gameId || '').then(pld => setGameInfo('data' in pld ? pld.data : undefined))
    }, [])

    const {user, isMobile} = useTypedSelector((state) => {
        return {
            user: state.userSlice.user?.id,
            isMobile: state.appSlice.isMobile
        }
    })

    useEffect(() => {
        document.getElementById('root')!.style.overflow = 'hidden'
        return () => {
            document.getElementById('root')!.style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        let prevPage = sessionStorage.getItem("previousPage");
        if (!prevPage) {
            prevPage = window.origin;
        }
        getGameInfo(gameId || '')
        createSession({
            GameId: gameId,
            ProviderId: providerId,
            ReturnUrl: prevPage,
            Device: isMobile ? 'mobile' : 'desktop',
            Lang: i18n.language
        }).then((pld) => {
            if ('data' in pld) {
                if (isMobile) window.location.href = pld.data.SessionUrl
            } else {
                window.location.href = prevPage || '/'
            }
        })
    }, [location.pathname, i18n.language])


    useEffect(() => {

        if (isError) {
            navigate(-1)
        }
    }, [isSuccess, isError])

    useEffect(() => {
        if (!user) navigate('/')
        if (sessionData) setDataGame(sessionData?.SessionUrl);
        if (infoData) setGameInfo(infoData);
    }, [sessionData, user, infoData])


    if (isLoading || isLoadingInfo) return <AppLoader/>

    if (isMobile) {
        return (
            <ContainerMobile>
                <Iframe src={dataGame} frameBorder={0} allowFullScreen={true}
                        height={"100%"} width={"100%"}/>
            </ContainerMobile>
        )
    } else {
        return (<DesktopGame dataGame={dataGame} dataInfo={gameInfo}/>)
    }
};

export default Game;

const Iframe = styled.iframe`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 152px);
`;

const ContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;