import React, {useEffect, useState} from 'react';
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {useNavigate} from "react-router-dom";
import {useCreateWalletSessionMutation} from "../redux/api/gamesApi";
import styled from "styled-components";
import {ReactComponent as BackIcon} from '../assets/images/mobile_arrow_back_icon.svg'
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import AppLoader from "../assets/loaders/AppLoader";
import {useCustomToast} from "../hooks/useCustomToast";

const Horses = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [createWalletSession, {isLoading}] = useCreateWalletSessionMutation();
    const [dataGame, setDataGame] = useState<string>("");
    const [error, setError] = useState('');
    const {isMobile} = useTypedSelector((state) => {
        return {
            isMobile: state.appSlice.isMobile
        }
    })
    const callToast = useCustomToast({text: error || 'some error', type: "error"})

    useEffect(()=>{
        callToast()
    },[error])

    useEffect(() => {
        document.getElementById('root')!.style.overflow = 'hidden'
        createWalletSession()
            .then((pld) => {
                if ('data' in pld) {
                    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
                        window.location.href = pld.data.SessionUrl;
                    } else {
                        setDataGame(pld.data.SessionUrl);
                    }
                }
                if ('error' in pld && 'data' in pld.error) {
                    setError(t(`common.error.${pld.error.data.code}`))
                }
            })
            .catch(() => {
                setError(t(`common.error.400`))
            })
        return () => {
            document.getElementById('root')!.style.overflow = 'auto'
        }
    }, [])
    if (isLoading) return <AppLoader/>
    if (isMobile) {
        return (
            <ContainerMobile>
                <Iframe src={dataGame} frameBorder={0} allowFullScreen={true}
                        height={"100%"} width={"100%"}/>
                <ContainerButton title={`${t(`common.options.back_to_bwin`)}`}>
                    <BackIcon style={{transform: "rotate(-90deg)"}} onClick={() => {
                        navigate("/")
                    }}/>
                </ContainerButton>
            </ContainerMobile>
        )
    } else {
        return (
            <Container>
                <iframe title={"horses"} src={dataGame} frameBorder={0}
                        allowFullScreen={true} height={"100%"} width={"100%"}/>
            </Container>
        );
    }

};

export default Horses;


const Iframe = styled.iframe`
  position: fixed;
  top: 44px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0 0 45px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 152px);
`;

const ContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContainerButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px
`;
