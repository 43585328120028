import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

export type validateMessage = {
    message: string,
    regExp: RegExp | string
}

export type conditionMessage = {
    message: string,
    status: boolean
}

const useValidate = (value: string, validArray?: validateMessage[], conditions?: conditionMessage[], dep: string[] = []) => {
    const [isError, setIsError] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>("");
    const {t} = useTranslation();

    useEffect(() => {
        setIsError(false);
        setHelperText("");
        validateValue(value);
    }, [value, ...dep]);

    const validateValue = (value: string) => {
        if (validArray) {
            for (let i = 0; i < validArray.length; i++) {
                if (!new RegExp(validArray[i].regExp, "g").test(value)) {
                    setIsError(true);
                    setHelperText(t(validArray[i].message));
                    return;
                }
            }
        }

        if (conditions)
            for (let i = 0; i < conditions.length; i++) {
                if (conditions[i].status) {
                    setIsError(true);
                    setHelperText(conditions[i].message);
                    return;
                }
            }
    }
    return {
        isError,
        helperText,
    }
};

export default useValidate