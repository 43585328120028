export const ProvidersImages = [
    {
        name: "Yggdrasil",
        image: 'provider_Yggdrasil_logo.png',
    },
    {
        name: "Wazdan",
        image: 'provider_Wazdan_logo.png',
    },
    {
        name: "Triple PG",
        image: 'provider_Tpg_logo.png',
    },
    {
        name: "Thunderkick",
        image: 'provider_Thunderkick_logo.png',
    },

    {
        name: "Splitrock",
        image: 'provider_Splitrock_logo.png',
    },
    {
        name: "Spearhead Studios",
        image: 'provider_Spearhead_logo.png',
    },
    {
        name: "Slotmill",
        image: 'provider_Slotmill_logo.png',
    },
    {
        name: "Skywind Casino",
        image: 'provider_Skywind-Group_logo.png',
    },
    {
        name: "Red Tiger Gaming",
        image: 'provider_Rtg-Slots_logo.png',
    },
    {
        name: "Revolver Gaming",
        image: 'provider_Revolver-Gaming_logo.png',
    },
    {
        name: "Relax Gaming",
        image: 'provider_Relax-Gaming_logo.png',
    },
    {
        name: "Quickspin",
        image: 'provider_Quickspin_logo.png',
    },
    {
        name: "Push Gaming",
        image: 'provider_Push-Gaming_logo.png',
    },
    {
        name: "playtech",
        image: 'provider_playtech_logo.png',
    },
    {
        name: "Pragmatic Play Casino",
        image: 'provider_Pragmatic-Play_logo.png',
    },
    {
        name: "playson",
        image: 'provider_Playson_logo.png',
    },
    {
        name: "PlayPearls",
        image: 'provider_Playpearls_logo.png',
    },
    {
        name: "playngo",
        image: 'provider_Playngo_logo.png',
    },
    {
        name: "OneTouch",
        image: 'provider_OneTouch_logo.png',
    },
    {
        name: "OMI Gaming",
        image: 'provider_OMI-Gaming_logo.png',
    },
    {
        name: "Nolimit City",
        image: 'provider_Nolimit-City_logo.png',
    },
    {
        name: "NetGame",
        image: 'provider_NetGame_logo.png',
    },
    {
        name: "NetEnt",
        image: 'provider_Netent_logo.png',
    },
    {
        name: "Maverick",
        image: 'provider_Maverick_logo.png',
    },
    {
        name: "Lady Luck",
        image: 'provider_Lady-Luck_logo.png',
    },
    {
        name: "Kiron Interactive",
        image: 'provider_Kiron_logo.png',
    },
    {
        name: "Kalamba Games",
        image: 'provider_Kalamba-Games_logo.png',
    },
    {
        name: "Iron Dog Studio",
        image: 'provider_Iron-Dog_logo.png',
    },
    {
        name: "Hacksaw Gaming",
        image: 'provider_Hacksaw_logo.png',
    },
    {
        name: "Habanero",
        image: 'provider_Habanero_logo.png',
    },
    {
        name: "Gamzix",
        image: 'provider_Gamzix_logo.png',
    },

    {
        name: "Gamefish Global",
        image: 'provider_Gamefish-Global_logo.png',
    },
    {
        name: "GameArt",
        image: 'provider_gameart_logo.png',
    },
    {
        name: "Fugaso",
        image: 'provider_Fugaso_logo.png',
    },
    {
        name: "Fantasma Games",
        image: 'provider_Fantasma-Games_logo.png',
    },
    {
        name: "Evoplay",
        image: 'provider_Evoplay_logo.png',
    },
    {
        name: "Dragoon Soft",
        image: 'provider_Dragon-soft_logo.png',
    },
    {
        name: "CQ9 Casino",
        image: 'provider_Cq9_logo.png',
    },
    {
        name: "Booongo",
        image: 'provider_Booongo_logo.png',
    },
    {
        name: "Blueprint Gaming",
        image: 'provider_Blueprint-Gaming_logo.png',
    },

    {
        name: "BB Games",
        image: 'provider_BB-games_logo.png',
    },
    {
        name: "1x2 Gaming",
        image: 'provider_1x2Gaming_logo.png',
    },
    {
        name: "Mobilots",
        image: 'provider_Mobilots_logo.png',
    },
    {
        name: "microgaming",
        image: 'provider_microgaming_logo.png',
    },
    {
        name: "merkur",
        image: 'provider_merkur_logo.png',
    },
    {
        name: "konami",
        image: 'provider_konami_logo.png',
    },
    {
        name: "kajot",
        image: 'provider_kajot_logo.png',
    },
    {
        name: "igt",
        image: 'provider_igt_logo.png',
    },
    {
        name: "igrosoft",
        image: 'provider_igrosoft_logo.png',
    },
    {
        name: "greentube",
        image: 'provider_greentube_logo.png',
    },

    {
        name: "aristocrat",
        image: 'provider_aristocrat_logo.png',
    },
    {
        name: "apollo",
        image: 'provider_apollo_logo.png',
    },
    {
        name: "amatic",
        image: 'provider_amatic_logo.png',
    },
]


export const TvProvidersImages = [
    {
        name: "Skywind Live",
        image: 'provider_Skywind-Group_logo.png',
    }, {
        name: "Evolution Gaming",
        image: 'provider_evolution_logo.png',
    }, {
        name: "Ezugi",
        image: 'provider_ezugi_logo.png',
    }, {
        name: "eBET",
        image: 'provider_ebet_logo.png',
    }, {
        name: "Pragmatic Play Live",
        image: 'provider_Pragmatic-Play_logo.png',
    },
]