import {toast, TypeOptions} from "react-toastify";

interface IUseCustomToast {
    text: string
    type?: TypeOptions
}

export const useCustomToast = (props: IUseCustomToast) => {
    const {text, type} = props;
    return () => {
        if (toast.isActive(1)) {
            toast.dismiss(1);
            toast(text, {
                type: type,
                toastId: 2,
            })
        } else {
            if (toast.isActive(2)) toast.dismiss(2);
            toast(text, {
                type: type,
                toastId: 1,
            })
        }
    }

}
