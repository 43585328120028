export enum TABLES {}

export enum SORT_ORDERS {
    ASC = "ASC",
    DESC = "DESC",
}

export enum CAPTCHA_KEY {
    CLIENT_KEY = '6LfLSiwgAAAAAA3GaQGM6_EFJ7ukkooUfYgkQMde'
}

export const STORAGE_KEY = "uniquekeygoodwingames";