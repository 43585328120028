import React from 'react';
import './Loader.css'

interface ILoader{
    width?: number
}


const Loader = (props: ILoader) => {
    const {width} =props
    return (
        <div id="wrapper">
            <div className="profile-main-loader">
                <div className="loader" style={{width: width, height: width}}>
                    <svg className="circular-loader" viewBox="25 25 50 50">
                        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#E7B556"
                                strokeWidth="2"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Loader;
