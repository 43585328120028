import React from 'react';
import styled from "styled-components";
import {ReactComponent as Arrow} from "assets/images/btn_up_arrow_icon.svg"

type ArrowButtonProps = {
    onClick: () => void,
    styles?: object
}

const ArrowButton = (props: ArrowButtonProps) => {
    return (
        <Wrapper id={`arrow-button`} style={props.styles} onClick={() => props.onClick()}>
            <Arrow/>
        </Wrapper>
    );
};

export default ArrowButton;


const Wrapper = styled.div`
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  cursor: pointer;
  background: ${({theme}) => theme.secondary.main};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 8px;
  filter: drop-shadow(0px 0px 4px #000000);

  &:hover {
    background: #222a49;
  }

  &:active {
    background: #1c233b;
  }
`
