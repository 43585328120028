import React, {useEffect} from 'react';
import styled, {css} from "styled-components";
import {ReactComponent as GoldIcon} from 'assets/images/jackpot_star_gold_desktop_icon.svg';
import {ReactComponent as BronzeIcon} from 'assets/images/jackpot_star_bronze_desktop_icon.svg';
import {ReactComponent as SilverIcon} from 'assets/images/jackpot_star_silver_desktop_icon.svg';
import {useTypedDispatch, useTypedSelector} from "../hooks/useTypedRedux";
import {useSocket} from "../services/SocketService";
import {updateJackpot} from "../redux/slices/appSlice";
import {device} from "../constants/deviceSizes";
import {useTranslation} from "react-i18next";

const JackpotStarsDesktop = () => {
    const {t, i18n} = useTranslation();

    const {jackpot} = useTypedSelector((state => {
        return {
            jackpot: state.appSlice.jackpot,
        }
    }))

    const socket = useSocket();
    const dispatch = useTypedDispatch();

    useEffect(() => {
        socket.on("jackpot", data => {
            dispatch(updateJackpot(data))
        })
    }, [])

    const stars = [
        {
            icon: <Gold/>,
            title: <div style={{color: '#B39942'}}>{t(`pages.games.grand`)}</div>,
            key: 'gold'
        },
        {
            icon: <Silver/>,
            title: <div style={{color: '#868480'}}>{t(`pages.games.minor`)}</div>,
            rotate: '20deg',
            key: 'silver'
        },
        {
            icon: <Bronze/>,
            title: <div style={{color: '#835E48'}}>{t(`pages.games.mini`)}</div>,
            rotate: '-30deg',
            key: 'bronze'
        }
    ]

    const isRight = i18n.dir() === 'rtl';
    return (
        <Wrapper isRight={i18n.dir() === 'rtl'} isOnlyOne={jackpot.length === 1}>
            {jackpot.length && stars
                .slice(0, jackpot.length)
                .map((star, index) => {
                    let currJackpot = jackpot.find(jack => jack.name === star.key);
                    return <React.Fragment key={`jackpot-${star.key}`}>
                        {currJackpot
                         ? <Icon rotate={star.rotate}>
                            <Text>
                                <div>{star.title}</div>
                                <MoneyText isRight={isRight}>{currJackpot.currentJackpot} TND</MoneyText>
                                <div>{t(`pages.games.winner`)}:</div>
                                <div>{currJackpot.lastWinner}</div>
                            </Text>
                            {star.icon}
                        </Icon>
                            :<div/>}
                    </React.Fragment>
                })}
        </Wrapper>
    );
};

export default JackpotStarsDesktop;

const MoneyText = styled.div<{isRight: boolean}>`
  color: black;
  font-size: 24px;
  direction: ${({isRight}) => isRight ? 'rtl' : 'ltr'};
  @media (${device.laptopL}) {
    font-size: 20px;
  }
  font-weight: 900;
`

const Wrapper = styled.div<{ isRight: boolean, isOnlyOne: boolean }>`
  direction: ltr;
  z-index: 100;
  position: absolute;
  width: 30vw;
  height: 100%;
  ${({isRight}) => !isRight ? 'right: 5vw' : 'left: 5vw'};

  > :nth-child(1) {
    top: 0;
    left: 0;
    ${({isOnlyOne}) => isOnlyOne && css`
      transform: translate(20%, 30%);
      scale: 1.5;
    `};
  }

  > :nth-child(2) {
    left: 45%;
    transform: translate(-50%, 0);
    bottom: -20px;
  }

  > :nth-child(3) {
    right: 0;
    top: 20px;
    
  }

`

const Text = styled.div<{ extra?: string }>`
  width: 100%;
  color: white;
  font-size: 18px;
  @media (${device.laptopL}) {
    font-size: 14px;
  }
  z-index: 100;
  position: absolute;
  padding-top: 20px;
  transform: translate(50%, 50%);
  right: 50%;
  bottom: 50%;
  text-align: center;
  text-transform: uppercase;
  ${({extra}) => extra};
`

const Icon = styled.div<{ rotate?: string }>`
  display: flex;
  rotate: ${({rotate}) => rotate};
  right: 0;
  position: absolute;
  align-items: center;
  width: 50%;
  height: 50%;
  justify-content: center;

  & svg {
    animation: pulse 1s linear 0s infinite;
    width: 100%;
    height: 100%;
    @keyframes pulse {
      0% {
        transform: scale(0.9);
      }
      15% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(0.9);
      }
    }
  }
`


const Gold = styled(GoldIcon)`

`

const Silver = styled(SilverIcon)`
  animation-delay: 0.3333s !important;
`

const Bronze = styled(BronzeIcon)`
  animation-delay: 0.6667s !important;
`
