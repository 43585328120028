import { memo, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MegaphoneIcon } from "assets/images/header_megaphone_icon.svg";
import InfoContainer from "./InfoContainer";
import { useTranslation } from "react-i18next";
import { getStorage } from "hooks/useStorage";
import ImageIcon from "ui/material-kit/ImageIcon";
import CurrentDate from "ui/CurrentDate";
import Button from "ui/material-kit/Button";
import ProfileContainer from "./ProfileContainer";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { device } from "constants/deviceSizes";

const HeaderDesktop = () => {
  const logo = useMemo(() => {
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    return require(`../../../${PATH}`);
  }, []);

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <ImageIcon
        style={{ maxWidth: 225, maxHeight: 45, justifySelf: "flex-start" }}
        src={logo}
        alt={"desktop-logo"}
        onClick={() => {
          navigate("/");
        }}
      />

      <Wrapper
        style={{
          gridTemplateColumns: "repeat(3,1fr)",
          maxWidth: 300,
          justifySelf: "center",
        }}
      >
        <CurrentDate />
        <Button
          id={`go_to_promotions`}
          onClick={() => navigate("/promotions")}
          style={{ width: 168 }}
          color={"secondary"}
        >
          <PromoContainer>
            <MegaphoneIcon />
            {t(`common.header.promotions`)}
          </PromoContainer>
        </Button>
      </Wrapper>

      {user ? (
        <>
          <InfoContainer />
        </>
      ) : (
        <div />
      )}

      <Wrapper style={{ gridTemplateColumns: "repeat(3,1fr)" }}>
        <ProfileContainer />
      </Wrapper>
    </Container>
  );
};

export default memo(HeaderDesktop);

const Container = styled.div`
  z-index: 1000000;
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 70px;
  grid-column-gap: 20px;
  grid-template-columns:
    minmax(0px, 150px) minmax(300px, 2fr) minmax(300px, 2fr)
    260px;
  align-items: center;
  background-color: ${({ theme }) => theme.background.main};

  @media (${device.laptopL}) {
    padding: 0 10px;
    grid-column-gap: 40px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  align-items: center;
`;

const PromoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${({ theme }) => theme.primary.light};
`;
