/*eslint-disable*/
import { eventDispatch } from "./useEventListener";

import _ from "lodash";
import { STORAGE_KEY } from "constants/config";

window.putStorageHistory = [];

declare global {
  interface Window {
    [STORAGE_KEY]: any;
    putStorageHistory: number[];
  }
}

interface PutStorageOptions {
  silent?: boolean;
}

export const putStorage = (
  path: string,
  value: any,
  options?: PutStorageOptions
) => {
  const { silent = false } = options ?? {};
  if (!window?.[STORAGE_KEY]) {
    window[STORAGE_KEY] = {};
  }
  _.set(window[STORAGE_KEY], path, value);
  if (!silent) {
    eventDispatch(`UPDATE_STORAGE`);
    window.putStorageHistory.push(+new Date());
    //console.log('testStorage')
    try {
      //  console.log( path, value, window[STORAGE_KEY], 'test');
    } catch (e) {
      //   console.log(e)
    }
  }
};

export const getStorage = (
  getPathContent?: ((state: any) => any) | string,
  defaultValue?: any
) => {
  if (!getPathContent) {
    return window[STORAGE_KEY];
  }
  let newGetPathContent: (state: any) => any;
  if (typeof getPathContent === `string`) {
    newGetPathContent = (state) => _.get(state, getPathContent, defaultValue);
  } else {
    newGetPathContent = getPathContent;
  }
  return newGetPathContent(window[STORAGE_KEY]);
};

export const clearStorage = (fieldsToPersist?: string[]) => {
  const temp = {} as any;
  fieldsToPersist?.forEach?.((field) => {
    const value = window[STORAGE_KEY][field];
    if (value !== undefined) {
      temp[field] = value;
    }
  });
  window[STORAGE_KEY] = { ...temp };
  eventDispatch(`UPDATE_STORAGE`);
  return;
};

/*eslint-enable*/
