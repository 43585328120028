import React, {useEffect, useRef} from 'react';
import {useTypedSelector} from "hooks/useTypedRedux"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import styled from "styled-components";
import useDimensions from "react-cool-dimensions";
import Slider from "../components/slider";

import MainGamesBlock from "../ui/MainGamesBlock";
import Button from "../ui/material-kit/Button";
import {ReactComponent as ArrowDrop} from "assets/images/main_arrow_icon.svg";
import PromotionsCards from "../components/containers/PromotionsCards";
import MainCards from "../components/containers/MainCards";
import ContainerTitle from "../ui/ContainerTitle";


const MainDesktop = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const {user, banners} = useTypedSelector((state => {
        return {
            user: state.userSlice.user || undefined,
            banners: state.appSlice.banners
        }
    }))
    const fields = {
        top_games: t(`pages.main.top_games`),
    }

    const scrollToTop = () => {
        document.querySelector('#main-wrapper')?.scrollTo({top: 0, behavior: 'smooth'})
    }

    const {observe, width} = useDimensions({});
    return (
        <>
            <Slider slides={banners}/>
            <DesktopContainer ref={observe}>
                <PromotionsCards isAuth={user !== undefined}/>
                <MainCards fullWidth={width}/>
                <ContainerTitle title={fields.top_games}/>
                <MainGamesBlock/>
                <ButtonStart onClick={scrollToTop} color={'secondary'}
                             id={'main-scroll'}>
                    <ArrowDrop/>
                </ButtonStart>
            </DesktopContainer>

        </>
    );
};

export default MainDesktop;

const DesktopContainer = styled.div`
  padding: 20px 40px;
  grid-gap: 20px;
  display: grid;
  grid-auto-rows: min-content;
`

const ButtonStart = styled(Button)`
  min-width: 113px;
  width: 113px;
  height: 42px;
  justify-self: center;
  background-color: ${({theme}) => theme.text.black};
`