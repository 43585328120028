import React from 'react';
import styled from "styled-components";

const ErrorWindow = ({error, resetErrorBoundary}: any) => {
    return (
        <Wrapper>
            <h1>{error}</h1>
            <Button onClick={() => window.location.reload()}>
                Try again
            </Button>
        </Wrapper>
    );
};

export default ErrorWindow;

const Wrapper = styled.div`
  background-color: white;
  color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Button = styled.button`

`
