import { createSlice } from "@reduxjs/toolkit";
import { transactionAPI } from "../api/transactionApi";
import { IBet, ICashBack, ITransaction, ITransactionState } from "../types/transactionTypes";

const initialState: ITransactionState = {
    transactionsCount: 0,
    cashbackOperationsCount: 0,
    betsCount: 0,
    cashbackOperations: [],
    transactions: [],
    bets: [],
}

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        clearTransactions: (state) => {
            state.transactionsCount = 0;
            state.cashbackOperationsCount = 0;
            state.betsCount = 0;
            state.cashbackOperations = [];
            state.transactions = [];
            state.bets = [];
        },
    },
    extraReducers: (builder => {
        builder
            .addMatcher(
                transactionAPI.endpoints.getTransactions.matchFulfilled,
                (state, action) => {
                    state.transactionsCount = action.payload.count
                    if (action.meta.arg.originalArgs.type === "cashback") {
                        // getRequest(state.transactionsCount,action.payload.count,
                        //     state.cashbackOperations,)
                        state.cashbackOperationsCount = action.payload.count
                        action.meta.arg.originalArgs.action === "add" ?
                            state.cashbackOperations = [...state.cashbackOperations, ...action.payload.data]
                            : state.cashbackOperations = action.payload.data
                    } else {
                        state.transactionsCount = action.payload.count
                        action.meta.arg.originalArgs.action === "add" ?
                            state.transactions = [...state.transactions, ...action.payload.data]
                            : state.transactions = action.payload.data
                    }
                })
            .addMatcher(
                transactionAPI.endpoints.getBets.matchFulfilled,
                (state, action) => {
                    state.betsCount = action.payload.count
                    action.meta.arg.originalArgs.action === "add" ?
                        state.bets = [...state.bets, ...action.payload.data]
                        : state.bets = action.payload.data
                })
    })
})

export default transactionSlice.reducer
export const { clearTransactions } = transactionSlice.actions

