import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "assets/images/icons/back_icon.svg";
import styled from "styled-components";

interface PaginationProps {
  count: number;
  setPage: (page: number) => void;
  page: number;
  className?: string;
}

const MIN_PAGES = 5;
const BETWEEN_PAGES = 1;
const MIN_START_PAGES = 3;
const MIN_END_PAGES = 1;

export const Pagination = (props: PaginationProps) => {
  const { page, setPage, count } = props;

  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    const newPages: number[] = [];

    // TODO: А как это работает ? Может комменты написать...
    if (count <= MIN_PAGES) {
      for (let i = 0; i < count; i++) {
        newPages[i] = i + 1;
      }
    } else {
      if (page <= MIN_START_PAGES || page >= count - MIN_END_PAGES + 1) {
        if (page === count) {
          newPages[1] = 1;
        } else {
          for (let i = 0; i < MIN_START_PAGES; i++) {
            newPages[i] = i + 1;
          }
        }

        const countEndItems = count - MIN_START_PAGES;

        if (countEndItems <= MIN_END_PAGES) {
          for (let i = 0; i < countEndItems; i++) {
            newPages[MIN_START_PAGES + i] = MIN_START_PAGES + i - 1;
          }
        } else {
          if (newPages[MIN_START_PAGES - 1] === page) {
            newPages[MIN_START_PAGES] = page + 1;
            newPages[MIN_START_PAGES + 1] = NaN;
            for (let i = MIN_END_PAGES - 1, j = 0; i >= 0; i--, j++) {
              newPages[MIN_START_PAGES + 2 + j] = count - i;
            }
          } else {
            newPages[MIN_START_PAGES] = NaN;
            for (let i = MIN_END_PAGES - 1, j = 0; i >= 0; i--, j++) {
              newPages[MIN_START_PAGES + 1 + j] = count - i;
            }
            if (newPages[newPages.length - MIN_END_PAGES] === page) {
              newPages.splice(newPages.length - MIN_END_PAGES, 0, page - 1);
            }
          }
          if (
            newPages[MIN_START_PAGES] ===
            newPages[newPages.length - MIN_END_PAGES] - 1
          ) {
            newPages.splice(MIN_START_PAGES + 1, 1);
          } else if (
            newPages[newPages.length - MIN_END_PAGES - 1] - 1 ===
            newPages[MIN_START_PAGES - 1]
          ) {
            newPages.splice(MIN_START_PAGES, 1);
          }
        }
      } else {
        for (let i = 0; i < 1; i++) {
          newPages[i] = i + 1;
        }

        let index = 1;
        for (let i = 0; i < BETWEEN_PAGES; i++) {
          if (page - BETWEEN_PAGES + i <= 1) {
            continue;
          }
          if (page - BETWEEN_PAGES + i - 1 === 1) {
            newPages[1 + i] = page - BETWEEN_PAGES + i;
            index = 1 + BETWEEN_PAGES + i;
          } else {
            if (i === 0) {
              newPages[1] = NaN;
            }
            newPages[1 + i + 1] = page - BETWEEN_PAGES + i;
            index = 1 + BETWEEN_PAGES + i + 1;
          }
        }

        newPages[index] = page;

        for (let i = 0; i < BETWEEN_PAGES; i++) {
          if (page + BETWEEN_PAGES + i >= count - MIN_END_PAGES + 1) {
            continue;
          }
          newPages[index + i + 1] = page + i + 1;
        }

        let endEmptyIndex = index + BETWEEN_PAGES - 1;

        if (newPages[index + BETWEEN_PAGES] < count - MIN_END_PAGES + 1) {
          if (
            count - MIN_END_PAGES + 1 - newPages[index + BETWEEN_PAGES] ===
            1
          ) {
            endEmptyIndex = index + BETWEEN_PAGES;
          } else {
            endEmptyIndex = index + BETWEEN_PAGES + 1;
            newPages[endEmptyIndex] = NaN;
          }
        }

        for (let i = 0; i < MIN_END_PAGES; i++) {
          newPages[endEmptyIndex + 1 + i] = count - MIN_END_PAGES + i + 1;
        }
      }
    }

    setPages(newPages);
  }, [page, count]);

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < count) setPage(page + 1);
  };

  const handleClickPage = (clickPage: number) => {
    if (page !== clickPage) setPage(clickPage);
  };

  return (
    <PaginationList style={{ direction: "ltr" }}>
      <PaginationItem>
        <IconButtonLeft onClick={handlePrevPage} id="pagination_prev_page">
          <ArrowIcon />
        </IconButtonLeft>
      </PaginationItem>
      {pages.map((item, index) =>
        !!item ? (
          <PaginationItem key={item}>
            <IconButtonActive
              buttonActive={item === page}
              onClick={() => handleClickPage(item)}
              id={`pagination_page_${item}`}
            >
              {item}
            </IconButtonActive>
          </PaginationItem>
        ) : (
          <PaginationItem key={`none_pagination_${index}`}>
            <IconButtonLeft disabled>...</IconButtonLeft>
          </PaginationItem>
        )
      )}
      <PaginationItem>
        <IconButtonRight onClick={handleNextPage} id="pagination_next_page">
          <ArrowIcon />
        </IconButtonRight>
      </PaginationItem>
    </PaginationList>
  );
};

const PaginationList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0;
  margin: 0;

  @media (max-width: 475px) {
    gap: 8px;
  }
`;

const PaginationItem = styled.li`
  list-style: none;
  padding: 0;
`;

const IconButtonLeft = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #515151;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  &:not(&:disabled) {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.text.white};

        svg path[stroke] {
          stroke: ${({ theme }) => theme.text.white};
        }
      }
    }
  }
`;

const IconButtonActive = styled.button<{ buttonActive: boolean }>`
  width: 30px;
  height: 34px;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: ${({ buttonActive }) =>
    buttonActive
      ? ({ theme }) => theme.text.white
      : ({ theme }) => theme.secondary.focused};

  &:not(&:disabled) {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.text.white};

        svg path[stroke] {
          stroke: ${({ theme }) => theme.text.white};
        }
      }
    }
  }
`;

const IconButtonRight = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #515151;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  svg {
    rotate: 180deg;
  }

  &:not(&:disabled) {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.text.white};

        svg path[stroke] {
          stroke: ${({ theme }) => theme.text.white};
        }
      }
    }
  }
`;
