import { useEffect, useState } from "react";

function useKeyPress(targetKey: string, handleFunction?: () => void, dep?: any[]) {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    function downHandler({ key }: {key: any}) {
        if (key === targetKey) {
            setKeyPressed(true);
            if (handleFunction) {
                handleFunction();
            }
        }
    }

    const upHandler = ({ key }: {key: any}) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, [dep]);
    return keyPressed;
}

export default useKeyPress
