import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Modal from "../../ui/material-kit/Modal";
import { useLocation } from "react-router-dom";
import { IPopup, IPopups } from "redux/types/userTypes";
import { useTypedSelector } from "hooks/useTypedRedux";
import { openLink } from "helpers/openLink";
import Button from "ui/material-kit/Button";
import { Pagination } from "ui/Pagination";
import { useCheckPopupViewedMutation } from "redux/api/userApi";
import LazyImage from "ui/LazyImage";
import { openPopupModal } from "redux/slices/userSlice";
import i18n from "i18n";

interface PopupModalProps {
  isOpen?: boolean;
  popups: IPopups;
  closeModal?: () => void;
  popupId?: number;
  onClose?: () => void;
  className?: string;
}

export const PopupModal = (props: PopupModalProps) => {
  const { className, popupId, onClose, isOpen, closeModal, popups } = props;
  const { t } = useTranslation();

  const [checkPopup] = useCheckPopupViewedMutation();
  const { rows, count } = popups;
  const isRight = i18n.dir() === "rtl";
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [popup, setPopup] = useState<IPopup | undefined>(undefined);

  useEffect(() => {
    if (
      isOpen &&
      pathname !== "/profile/messages" &&
      popups.rows.length &&
      !popup?.isViewed
    ) {
      setPopup(popups.rows[0]);
      setOpen(true);
    }
  }, [isOpen, popups, closeModal, pathname]);

  const { isMobile } = useTypedSelector((state) => {
    return {
      isMobile: state.appSlice.isMobile,
    };
  });

  useEffect(() => {
    if (popupId) {
      const index = rows.findIndex((item) => item.id === popupId);
      if (index > -1) {
        setPopup(rows[index]);
        setPage(index + 1);
        setOpen(true);
      }
    }
  }, [popupId, rows]);

  useEffect(() => {
    setPopup(rows[page - 1]);
  }, [page, rows]);

  useEffect(() => {
    if (popup && !popup.isViewed && open) {
      checkPopup({ id: popup.id });
    }
  }, [popup, checkPopup, open]);

  const handleClose = useCallback(() => {
    setOpen(false);
    localStorage.setItem("popupsWereOpened", "true");
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleNav = (link: string) => {
    openLink(link);
  };

  useEffect(() => {
    return () => {
      openPopupModal(false);
    };
  }, [open]);

  return (
    <Modal
      isFullScreen={false}
      title={!isMobile ? popup?.title : undefined}
      isOpen={open}
      onClose={handleClose}
      isPopupMessage
    >
      <CustomContainer isMobile={isMobile}>
        {popup && (
          <Wrapper isMobile={isMobile}>
            <ImageWrapper isMobile={isMobile}>
              <CustomImage
                isRight={isRight}
                src={popup.imageUrl}
                isMobile={isMobile}
              />
            </ImageWrapper>
            <Content
              isMobile={isMobile}
              dangerouslySetInnerHTML={{ __html: popup?.content }}
            />
          </Wrapper>
        )}
        {popup?.link && (
          <CustomButton
            onClick={() => handleNav(popup.link)}
            id="popup_modal_button"
          >
            {t(`common.modals.messages.play`)}
          </CustomButton>
        )}
      </CustomContainer>
      {pathname !== "/profile/messages" && rows.length > 1 && (
        <PaginationWrapper>
          <Pagination page={page} count={rows.length} setPage={setPage} />
        </PaginationWrapper>
      )}
    </Modal>
  );
};

const CustomContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  height: 621px;
  width: 100%;
  max-width: 1001px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 95vw;
      max-height: 75vh;
    `}
`;

const ImageWrapper = styled.div<{ isMobile: boolean }>`
  span {
    display: block !important;

    ${({ isMobile }) =>
      isMobile &&
      css`
        margin: 0 12px;
        text-align: center;
      `}
  }
`;

const CustomImage = styled(LazyImage)<{ isMobile: boolean; isRight: boolean }>`
  max-width: ${({ isMobile }) => (isMobile ? "327px" : "428px")};
  width: 100%;
  max-height: ${({ isMobile }) => (isMobile ? "251px" : "274px")};
  height: ${({ isMobile }) => (isMobile ? "251px" : "274px")};
  object-fit: contain;
  margin-top: 0;
  margin-inline-end: 14px;
  margin-bottom: 14px;
  float: ${({ isRight }) => (isRight ? "right" : "left")};

  ${({ isMobile }) =>
    isMobile &&
    css`
      float: none;
      margin: 0;
    `}
`;

const Wrapper = styled.div<{ isMobile: boolean }>`
  height: inherit;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-grow: 1;

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-top: 20px;
    `}
`;

const Content = styled.div<{ isMobile: boolean }>`
  grid-area: 2/2;
  margin: 0px 15px;
  text-overflow: ellipsis;

  hyphens: auto;
  hyphenate-limit-chars: 6 3 2;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 2;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 12px;
      font-size: 14px;
    `}

  * {
    word-break: break-word;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

const CustomButton = styled(Button)`
  width: 153px;
  text-transform: uppercase;
  padding: 10px;
`;

const PaginationWrapper = styled.div`
  margin-top: 15px;
`;
