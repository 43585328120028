import { AnyAction, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const HallOfFameMiddleware = (api: MiddlewareAPI, dispatch: Dispatch<AnyAction>, action: any) => {
    const { payload, meta, type } = action;
    const method = meta?.baseQueryMeta?.request?.method;
    const status = type.split("/")[2];
    if (status === "rejected") {
        errorMiddleware(api, dispatch, action);
    }
}

const errorMiddleware = (api: MiddlewareAPI, dispatch: Dispatch<AnyAction>, action: any) => {
    const { payload, meta, type } = action;
    const endpoint = meta.arg.endpointName;
    const method = meta?.baseQueryMeta?.request?.method;
    toast.error(` ${payload.data.message}`)
}
export default HallOfFameMiddleware;
