import {useEffect, useMemo, useState} from "react";

interface IObject {
    [key: string]:
        {
            isError: boolean
            helperText: string
        }
}

interface IValidationProps {
    validateObject: IObject
    callback: () => Promise<any>
    callbackSuccess?: (pld?: any) => void
    callbackError?: (pld?: any) => void
    callbackCatch?: (pld?: any) => void
    callbackFunc?: () => void
}

interface IReturn {
    isValidate: boolean
    checkValidation: () => void
    validate: IObject,
    clear: () => void,
}

const useTestValidation = (props: IValidationProps): IReturn => {
    const {callback, callbackSuccess, callbackError, callbackCatch, callbackFunc, validateObject} = props;
    const [isValidate, setIsValidate] = useState(true);

    //const keys = useMemo(() => Object.assign({}, Object.fromEntries(new Map(Object.keys(validateObject).map(item => [item, item])))), [])

    const checkValidation = () => {
        if (Object.values(validateObject).find(item => item.isError)?.isError) {
            setIsValidate(false);
        } else {
            setIsValidate(true)
            callback().then((pld: any) => {
                if ('data' in pld) {
                    if (callbackSuccess) callbackSuccess(pld.data)
                } else {
                    if (callbackError) callbackError(pld.error)
                }
            }).catch((pld: any) => {
                if (callbackCatch) callbackCatch(pld)
            })
            if (callbackFunc) callbackFunc()
        }
    }

    return {
        isValidate,
        checkValidation,
        validate: {...(!isValidate && {...validateObject})},
        clear: () => setIsValidate(true)
    }
}

export default useTestValidation;