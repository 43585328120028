import styled, {css, CSSProperties} from "styled-components";
import React from "react";
import {formatForId} from "../../helpers/formatString";

interface IconButtonProps {
    onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    children: React.ReactElement,
    style?: CSSProperties,
    className?: string,
    color?: string,
    id: string
}

const IconButton = (props: IconButtonProps) => {
    const {onClick, children, style, className, color, id} = props;
    return (
        <Container className={className} onClick={onClick} id={formatForId(`icon-button-${id}`)}
                   style={style} color={color}>
            {children}
        </Container>
    );
};

export default IconButton;

const Container = styled.div<{ color?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  text-transform: uppercase;

  ${({color}) => color === 'primary' && css`
    color: ${({theme}) => theme.text.black};
    background-color: ${({theme}) => theme.primary.main};

    &:hover {
      background-color: ${({theme}) => theme.primary.hover};
    }

    &:active {
      background-color: ${({theme}) => theme.primary.light};
    }
  `}
  ${({color}) => color === 'secondary' && css`
    color: ${({theme}) => theme.text.white};
    background-color: ${({theme}) => theme.secondary.main};

    &:hover {
      background-color: ${({theme}) => theme.text.black};
    }

    &:active {
      background-color: ${({theme}) => theme.secondary.focused};
    }
  `}
  &:disabled {
    cursor: default;
    background-color: ${({theme}) => theme.primary.lighter};
    border-color: ${({theme}) => theme.primary.lighter};
  }
`
