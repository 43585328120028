import styled, { css } from "styled-components";

export const ContentModal = styled.div<{ isFullScreen?: boolean, width?: string, maxWidth?: string, maxHeight?: string, height?: string, padding?: string, extra?: string }>`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 16px;
  justify-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => padding ? padding : '0 20px'};
  width: 100%;
  ${({ isFullScreen, maxWidth, maxHeight, width, height }) =>
    isFullScreen ?
      css`max-width: ${({ }) => maxWidth ? maxWidth : '700px'}; max-height: ${({ }) => maxHeight};` :
      css`width: ${({ }) => width}; height: ${({ }) => `calc(${height} - 82px)`};`}
  ${({ extra }) => extra}
`


export const ContainerHallOfFame = styled.div`
  margin: 0 auto 20px auto;
  max-width: 1074px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.hall_of_fame.background_color};
`
