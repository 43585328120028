import { useEffect } from "react";

const detectMobile = () => {
  // if ('userAgentData' in window.navigator) {
  //   console.log(`window.navigator.userAgentData := `, window.navigator.userAgentData);
  //   // @ts-ignore
  //   return Boolean(window.navigator.userAgentData?.mobile ?? false);
  // }

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const useBodyOverflow = (state: boolean) => {
  useEffect(() => {
    if (state) {
      document.body.style.overflow = "hidden";
      if (!detectMobile()) {
        document.body.style.width = `${window.innerWidth - 8}px`;
      }
    } else {
      document.body.style.overflow = "";
      document.body.style.width = "";
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.width = "";
    };
  }, [state]);

  return null;
};
