import React from 'react';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import format from "date-fns/format";
import {parseISO, toDate} from "date-fns";


type HistoryItemProps = {
    provider?: string,
    gameId?: string
    amount: string,
    date: string,
    status?: string,
    type?: string
    isOperations?: boolean
}

const HistoryItem = (props: HistoryItemProps): JSX.Element => {
    const {t} = useTranslation();
    return (
        <Container provider={props.provider || undefined}>
            {(props.gameId || props.provider) &&
                <ContainerColumn>
                    <Title>
                        {t(`pages.profile.provider`)}
                    </Title>
                    <Text title={props.gameId}>
                        {props.provider || ''} {props.gameId && (` &  ${props.gameId}`)}
                    </Text>
                </ContainerColumn>
            }

            <ContainerColumn style={{gridRow: props.provider ? '2/3' : 'unset'}}>
                <Title>
                    {props.isOperations ? props.type : t(`pages.profile.date`)}
                </Title>
                <Text>
                    {format(parseISO(props.date), `dd.MM.yyyy HH:mm`)}
                </Text>
            </ContainerColumn>

            {!props.isOperations && props.status && <ContainerColumn>
                <Title>
                    {t(`pages.profile.status`)}
                </Title>
                <Text>
                    {`${props.type || props.status}`}
                </Text>
            </ContainerColumn>
            }
            <ContainerColumn style={!props.status ? {justifyItems: 'end'} : {}}>
                <Title>
                    {t(`pages.profile.amount`)}
                </Title>
                <Text style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {`${props.amount} TND`}
                </Text>
            </ContainerColumn>
        </Container>
    );
};

export default HistoryItem;

const Container = styled.div<{ provider?: string }>`
  height: min-content;
  padding: 8px 16px;
  background: ${({theme}) => theme.secondary.main};
  border-radius: 5px;
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 12px;
  grid-column-gap: 10px;
  text-transform: uppercase;
  font-size: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  > :last-child {
    text-align: right;
  }

  ${({provider}) =>
          provider && css`
            grid-template-rows: min-content min-content;
            grid-template-columns: 8fr max-content;

            > :nth-child(n+3) {

              text-align: right;
            }
          `}
`

const ContainerColumn = styled.div`
  display: grid;
  grid-row-gap: 2px;
`

const Title = styled.div`
  text-transform: capitalize;
  color: ${({theme}) => theme.secondary.disabled};
`

const Text = styled.div`
  color: ${({theme}) => theme.text.white};
`