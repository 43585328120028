import React from 'react';
import styled from 'styled-components';
import LazyImage from 'ui/LazyImage';
import {useTranslation} from "react-i18next";

import Button from "ui/material-kit/Button";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import useDimensions from "react-cool-dimensions";
import {userSelector} from "../../redux/selectors/userSelector";
import {isMobileSelector} from "../../redux/selectors/appSelector";

type DesktopCardProps = {
    onClick: () => void,
    icon: string,
    title: string,
    color?: string
}
const DesktopCard = (props: DesktopCardProps) => {
    const {onClick, icon, title, color} = props;
    const {t} = useTranslation();
    let img = require(`../../${icon}`);

    const isMobile = useTypedSelector(isMobileSelector)

    return (
        <Icon onClick={onClick} color={'secondary'} isMobile={isMobile} id={`card-${title}`}>
            <>
                <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                    <Image src={img} isMobile={isMobile} color={color}/>
                </div>
                <Title isMobile={isMobile}>{title}</Title>
            </>
        </Icon>
    );
};

export default DesktopCard;

const Icon = styled(Button)<{ isMobile: boolean }>`
  display: flex;
  min-width: 100px;
  max-width: 250px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.text.black};

  :hover {
    background-color: ${({theme}) => theme.secondary.main};
  }

  :focus {
    background-color: ${({theme}) => theme.secondary.focused};
  }

  padding: 10px;
  border-radius: 10px;
  align-items: center;
`

const Title = styled.div<{ isMobile: boolean }>`
  font-size: ${({isMobile}) => isMobile ? "16px" : '20px'};
  text-transform: uppercase;
  font-weight: 500;
  height: 26px;
  display: flex;
  align-items: self-end;
`

const Image = styled(LazyImage)<{ isMobile: boolean, color?: string }>`
  max-width: ${({isMobile}) => isMobile ? "55px" : '110px'};
  max-height: ${({isMobile}) => isMobile ? "55px" : '110px'};
  height: 100%;

  & svg > path {
    fill: ${({color}) => color};
  }
`
