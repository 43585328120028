import { Fragment, useEffect } from "react";
import styled from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { useGetGameListMutation } from "../redux/api/gamesApi";
import GameCard from "./GameCard";
import useDimensions from "react-cool-dimensions";
import { userIdSelector } from "../redux/selectors/userSelector";
import { isMobileSelector } from "../redux/selectors/appSelector";
import { gamesSelector } from "../redux/selectors/gamesSelector";

interface IPromotions {
  isDesktop?: boolean;
}

const MainGamesBlock = (props: IPromotions) => {
  const [getGamesList] = useGetGameListMutation();

  useEffect(() => {
    getGamesList({ data: { size: 30, type: "update" } });
  }, [getGamesList]);

  const user = useTypedSelector(userIdSelector);
  const isMobile = useTypedSelector(isMobileSelector);
  const games = useTypedSelector(gamesSelector);

  const { observe, width } = useDimensions({});

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <ContentMobile style={{ gridAutoRows: `${width}px` }}>
            {games.slice(0, 30).map((item, index) => (
              <Fragment key={`main-games-card-${index}`}>
                <Game ref={observe}>
                  <GameCard game={item} isAuth={!!user} isTv={false} />
                </Game>
              </Fragment>
            ))}
          </ContentMobile>
        </>
      ) : (
        <>
          <Content style={{ gridAutoRows: `${width}px` }}>
            {games.slice(0, 11).map((item, index) => (
              <Fragment key={`main-games-card-${index}`}>
                <Game ref={observe}>
                  <GameCard game={item} isAuth={!!user} isTv={false} />
                </Game>
              </Fragment>
            ))}
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export default MainGamesBlock;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
`;

const ContentMobile = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-gap: 6px;
  grid-template-rows: repeat(3, 1fr);

  margin-top: 6px;
`;

const Content = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr 1fr;

  > div:first-child {
    grid-area: 1/1/3/3;
  }
`;

const Game = styled.div`
  width: inherit;
  box-sizing: border-box;
  border-radius: 10px;
`;
