import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BannerImage from "assets/images/tv_games_banner_icon.webp"
import TvGamesPageContainer from "./TvGamesListContainer";
import Search from "../../ui/material-kit/Search";
import useInput from "../../hooks/useInput";
import { useGetProvidersTvQuery } from "../../redux/api/gamesApi";
import { useDebounce } from "../../hooks/useDebounce";
import ProvidersSwiper from "../../components/containers/ProvidersSwiper";
import { useSearchParams } from "react-router-dom";


const TvGames = () => {
    const { t } = useTranslation();
    const { } = useGetProvidersTvQuery();
    const search = useInput('')

    const fields = {
        search: t(`common.placeholder.search_tv_games`),
        password: t(`common.placeholder.password`),
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [provider, setProvider] = useState<string>(searchParams.get('provider') || '');
    const findGames = useDebounce(search.value, 1000);

    const changeProvider = useCallback((v: string) => {
        setSearchParams({ provider: v })
    }, [provider])

    useEffect(() => {
        const prov = searchParams.get('provider') || ''
        if (prov !== provider) setProvider(prov)
    }, [searchParams])

    const memoValue = useMemo(() => {
        return {
            game: findGames || undefined,
            provider: provider || undefined
        }
    }, [findGames, provider])

    return (
        <>
            <Banner>
                <Image alt={'banner'} src={BannerImage} />
            </Banner>
            <Container>
                <div style={{ width: '100%' }}>
                    <Search search={search} title={fields.search} isMobile={true} />
                </div>
                <ProvidersSwiper changeProvider={changeProvider} currentProvider={provider} />
                <div style={{ margin: '0 -4px' }}>
                    <TvGamesPageContainer value={memoValue} />
                </div>
            </Container>
        </>
    );
};

export default TvGames;

const Container = styled.div`
  padding: 10px;
  box-sizing: border-box;
  grid-gap: 14px;
  display: grid;
  grid-auto-rows: min-content;
`

const Banner = styled.div`
  width: 100%;
  position: relative;
  justify-self: center;
  height: 100%;
`

const Image = styled.img`
  width: 100vw;
`
