import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../ui/material-kit/Input";
import useInput from "../../hooks/useInput";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import { ReactComponent as CopyIcon } from "assets/images/copy_icon.svg";
import { useCustomToast } from "../../hooks/useCustomToast";

export const PersonalDataInfo = () => {
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  let fields = useMemo(
    () => ({
      id: t(`common.placeholder.id`),
      name: t(`common.placeholder.name`),
      balance: t(`common.placeholder.balance`),
      currency: t(`common.placeholder.currency`),
      id_copied: t(`messages.success.id_copied`),
    }),
    [t]
  );

  const callToast = useCustomToast({ text: fields.id_copied, type: "success" });

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(`${id.value.replace(/\s{2,}/g, " ")}`)
      .then(callToast);
  }, []);

  const id = useInput(`${fields.id.toUpperCase()}:  ${user?.id}`);
  const name = useInput(`${fields.name.toUpperCase()}:  ${user?.login}`);
  const balance = useInput(
    `${fields.balance.toUpperCase()}:  ${user?.balance}`
  );
  const currency = useInput(`${fields.currency.toUpperCase()}:  TND`);

  return (
    <>
      <Input
        {...id}
        isReadOnly={true}
        rightIcon={{
          icon: <CopyIcon />,
          extra: `width: 16px; height: 16px`,
          onClick: handleCopy,
        }}
        inputExtra={"cursor: pointer;"}
      />
      <Input {...name} isReadOnly={true} inputExtra={"cursor: pointer;"} />
      <Input {...balance} isReadOnly={true} inputExtra={"cursor: pointer;"} />
      <Input {...currency} isReadOnly={true} inputExtra={"cursor: pointer;"} />
    </>
  );
};
