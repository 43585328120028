import { ITheme } from "../interfaces/ITheme";

const theme: ITheme = {
  hall_of_fame: {
    text_color: "#fff",
    secondary_text_color: "#79531F",
    third_text_color: "#906325",
    main_color: "#4D2608",
    border_color: "#724E1D",
    background_color: "#202020",
    secondary_background_color: "#A1712B",
    third_background_color: "#C89D4B",
    background_hover: "#FFF6B1",
    font_size_primary: "24px",
    font_size_mobile_primary: "12px",
  },
  background: {
    main: "#202020",
  },
  primary: {
    hover: "#e3bd77",
    main: "#E7B556",
    light: "#E7B556",
  },
  secondary: {
    main: "#363636",
    focused: "#5F5F5F",
    disabled: "#A2A2A2",
  },
  text: {
    white: "#FFFFFF",
    black: "#000000",
  },
  success: {
    main: "#C89D4B",
  },
  error: {
    main: "#CB2011",
  },
  warning: {
    main: "#202020",
  },
};
export default theme;
