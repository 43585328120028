import React from 'react';
import styled from "styled-components";
import loading_button from "../lotties/loading_button_lottie.json";
import Lottie from "react-lottie-player";

type ButtonLottieProps = {
    width?: number,
    height?: number
}

const ButtonLottie = (props: ButtonLottieProps): JSX.Element => {
    return (
        <Wrapper>
            <Lottie
                loop
                play
                animationData={loading_button}
            />
        </Wrapper>
    );
};

export default ButtonLottie;


const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  div {
    width: 100%;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
`