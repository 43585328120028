import routingLinks from "constants/routingLinks";
import { getStorage } from "hooks/useStorage";
import { Fragment,   } from "react";
import ModalProfileItem from "./ModalProfileItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProfileLinksProps {
    onClose: () => void;
}

export const ProfileLinks = (props: IProfileLinksProps) => {
    const {onClose } = props;
    const { t } = useTranslation();
    const access = getStorage("APP_ACCESS");
   
    const objEnt: [string, { VISIBLE: boolean; PERMISSIONS: {} }][] = Object.entries(access);
    const correctAccess = objEnt.reduce(
        (acc: string[], [key, val]) => (val.VISIBLE ? [...acc, key] : acc),
        []
    );
    const navigate = useNavigate();

    return <>
    {routingLinks
        .filter(
        (item) =>
            item.key.includes("PROFILE_") && correctAccess.includes(item.key)
        )
        .map((item, index) => (
        <Fragment key={`${index}-${item.key}-menu-profile`}>
            <ModalProfileItem
            title={t(`common.profile.${item.lang_key}`)}
            icon={item.iconJsx}
            to={`${item.to}`}
            isMessages={item.key === 'PROFILE_MESSAGES'}
            navigate={() => {
                onClose();
                navigate(`${item.to}`);
            }}
            />
        </Fragment>
        ))}
    </>
}