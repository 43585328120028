import { AppSelector } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { IUser } from "../types/userTypes";

const userId: AppSelector<number | undefined> = (state) =>
  state.userSlice.user?.id;
const user: AppSelector<IUser | null> = (state) => state.userSlice.user;

export const userIdSelector = createSelector(userId, (thisUser) => thisUser);

export const userSelector = createSelector(user, (thisUser) => thisUser);
