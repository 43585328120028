import React, {useEffect, useState} from 'react';
import {useCreateGaminghubSessionMutation} from 'redux/api/gamesApi';
import {useTypedDispatch, useTypedSelector} from "hooks/useTypedRedux";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

import Loader from "../../ui/Loader/Loader";
import {updateAlert} from "../../redux/slices/appSlice";

type Gaminghub = {
    type?: string,
    isDesktop?: boolean;
}
const GaminghubSport = (props: Gaminghub) => {
    const {isDesktop} = props;
    const {user} = useTypedSelector(state => {
        return {
            user: state.userSlice.user,
        }
    });
    const {t} = useTranslation();
    const [CreateGaminghubSession, {isLoading}] = useCreateGaminghubSessionMutation();
    const [url, setUrl] = useState<string>('');
    const [IframeLoaded, setIframeLoaded] = useState(false);
    const dispatch = useTypedDispatch();

    useEffect(() => {
        setIframeLoaded(false);
        CreateGaminghubSession()
            // .then(x => "data" in x ? x.data : x)
            .then((payload) => {
                // console.log(`data := `, payload);
                if ('data' in payload && 'sessionUrl' in payload.data) {
                    setUrl(payload.data.sessionUrl);
                    // console.log(`payload.data.sessionUrl := `, payload.data.sessionUrl);
                }
                if ('error' in payload) {
                    dispatch(updateAlert({isOpen: true, status: "error", message: t(`common.alert.please_login`)}))
                }
            })
            .catch((err) => {
                console.log(`err := `, err);
            })
    }, [user?.id, isDesktop]);

    return (
        <SportContainer>
            {(!IframeLoaded || isLoading) && (
                <LoaderWrapper>
                    <Loader width={300}/>
                </LoaderWrapper>
            )}
            <Iframe src={url} onLoad={() => setIframeLoaded(true)} allowFullScreen/>

        </SportContainer>
    );
};

export default GaminghubSport;

const Iframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 400;
  left: 0;
`

const SportContainer = styled.section`
  width: 100%;
  height: calc(100% - 138px);
  top: 138px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 1024px) {
    transform: none;
    left: inherit;
    height: calc(100% - 44px);
    top: 44px;
  }
`

const LoaderWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 401;
  background-color: rgba(0, 0, 0, 0.59);
  display: flex;
  justify-content: center;
  align-items: center;
`
