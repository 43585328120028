import { useEffect } from "react";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BannerImage from "assets/images/games_banner_icon.webp";
import MostLikedGamesContainer from "../../components/containers/MostLikedGamesContainer";
import GamesPageContainerDesktop from "./GamesPageContainer.desktop";
import ContainerTitle from "../../ui/ContainerTitle";
import { useGetProvidersQuery } from "../../redux/api/gamesApi";
import { useGetJackpotMutation } from "../../redux/api/appApi";
import JackpotStarsDesktop from "../../ui/JackpotStarsDesktop";

const GamesDesktop = () => {
  const { t } = useTranslation();
  const {} = useGetProvidersQuery();
  const [getJackpot, { data }] = useGetJackpotMutation();

  useEffect(() => {
    getJackpot();
  }, []);

  const { isAuth, jackpot } = useTypedSelector((state) => {
    return {
      isAuth: !!state.userSlice.user,
      jackpot: state.appSlice.jackpot,
    };
  });

  return (
    <>
      <Banner>
        <Image alt={"banner"} src={BannerImage} />
        {isAuth && jackpot.length !== 0 && <JackpotStarsDesktop />}
      </Banner>
      <DesktopContainer>
        {process.env.REACT_APP_INSTANCE !== "maxgold" && (
          <>
            <ContainerTitle title={"Most Liked"} />
            <MostLikedGamesContainer />
          </>
        )}
        <GamesPageContainerDesktop />
      </DesktopContainer>
    </>
  );
};

export default GamesDesktop;

const DesktopContainer = styled.div`
  z-index: 1;
  padding: 16px 40px;
  grid-gap: 20px;
  display: grid;
  grid-auto-rows: min-content;
`;

const Banner = styled.div`
  width: 100%;
  position: relative;
  justify-self: center;
  background: ${({ theme }) =>
    `linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 32.71%)`};
  height: 30vw;
  max-height: 544px;
`;

const Image = styled.img`
  height: 45vw;
  top: 60%;
  max-height: 800px;
  transform: translate(0%, -50%);
  position: absolute;
`;
