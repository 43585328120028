import React from "react";
import styled from "styled-components";
import {IInput} from "interfaces/index";
import {ReactComponent as CloseIcon} from "assets/images/cross_icon.svg";
import {useTranslation} from "react-i18next";
import Input from "ui/material-kit/Input";
import {ReactComponent as SearchIcon} from "../../assets/images/search_icon.svg";

interface SearchProps {
    search: IInput
    title?: string
    isMobile?: boolean
}


const Search = (props: SearchProps): JSX.Element => {
    const {search, title, isMobile} = props;
    const {t} = useTranslation()

    return (
        <SearchContainer>
            <Input
                {...search} placeholder={title || t(`common.info.search_games`)}
                extra={' margin-bottom: 0; max-width: 723px'}
                inputExtra={isMobile ? 'padding-left: 40px; height: 40px; font-size: 14px; ::placeholder{font-size: 14px;}' : 'height: 66px; font-size: 24px; ::placeholder{font-size: 24px;}'}
                leftIcon={{
                    icon: <Icon/>,
                    extra: isMobile ? 'left:20px;' : '',
                    onClick: () => undefined,
                }}
                rightIcon={search.value !== '' ? {
                    icon: <CloseIcon/>,

                    onClick: () => search.onChange(''),
                } : undefined}
            />
        </SearchContainer>

    )
}

export default Search

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`


const Icon = styled(SearchIcon)`
  width: 20px;
  height: 20px;
  left:20px;
`