import MobileWrapper from "../../components/profile/MobileWrapper";
import { MessagesWrapper } from "components/profile/MessagesWrapper";

const MessagesMobile = (): JSX.Element => {
  return (
    <MobileWrapper
      title={"PROFILE_MESSAGES"}
      childrenResult={null}
      childrenInfo={<MessagesWrapper />}
    />
  );
};

export default MessagesMobile;
