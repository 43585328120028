import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";


type ListMenuProps = {
    title: JSX.Element | string,
    icon?: JSX.Element,
    onClick?: () => void,
}
const ListMenu = (props: ListMenuProps): JSX.Element => {
    const {title, icon, onClick} = props;
    const {t, i18n} = useTranslation();
    return (<ListMenuContainer  isRight={i18n.dir() === 'rtl'} onClick={onClick}>
        <span>
            {title}
        </span>
        {icon && <span>
            {icon}
        </span>}
    </ListMenuContainer>)

}

const ListMenuContainer = styled.div<{isRight: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 10px 0;
  color: ${({theme}) => theme.primary.light};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;
  :hover {
    background-color: #333;
  }

  :after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #333;
    position: absolute;
    bottom: 0;
  }

  span {
    text-transform: capitalize;
    padding: 0 10px;
    :last-child {
      right: 0;
    }
    svg {
      transform:  ${({isRight}) => isRight ? 'rotateY(180deg)' : 'rotateY(0deg)'};
    }
  
  }
`
export default ListMenu;
