interface GeneratePasswordProps {
    min?: number
    max?: number
    symbols?: string
}

export const GeneratePassword = (props: GeneratePasswordProps) => {
    const {
        min = 6,
        max = 12,
        symbols = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$%&\'*+—/=?^_`{|}()«»~'
    } = props
    let result = ''

    for (let i = 0; i < min; i++) {
        const num = Math.floor(Math.random() * symbols.length);
        result += symbols.substring(num, num + 1);
    }

    if (max > min) {
        const RANDOM = Math.floor(Math.random() * (max - min));

        for (let i = min; i < min + RANDOM; i++) {
            const num = Math.floor(Math.random() * symbols.length);
            result += symbols.substring(num, num + 1);
        }
    }

    return result
}