import React from 'react';
import styled from 'styled-components';
import LazyImage from 'ui/LazyImage';
import {useTranslation} from "react-i18next";
import Button from "../material-kit/Button";

type MainCardProps = {
    onClick: () => void,
    icon: string,
    title?: string,
}
const MainCard = (props: MainCardProps) => {
    const {onClick, icon, title} = props;
    const {t} = useTranslation();
    let img = require(`../../${icon}`);

    return (
        <>
            <Image src={img} onClick={onClick}/>
        </>
    );
};

export default MainCard;

const Image = styled(LazyImage)`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`