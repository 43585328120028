import React from 'react';
import {LazyLoadImage, LazyLoadImageProps} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const LazyImage = (props: LazyLoadImageProps): JSX.Element => {
    return (
        <LazyLoadImage
            {...props}
            draggable="false"
            effect="blur"

        />
    );
};

export default LazyImage;
